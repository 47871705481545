#product-count-title,
#product-value-title {
  font-size: 1.5rem;
}
#product-count,
#product-value {
  font-size: 1.5rem;
}

#inventroy-history-container {
  .sort {
    cursor: pointer;

    .material-icons-asc {
      transform: rotate(180deg);
    }
  }
}

@media (max-width: 768px) {
  #product-count-title,
  #product-value-title {
    font-size: 1.2rem;
  }
  #product-count,
  #product-value {
    font-size: 1rem;
  }

  #start-date {
    margin-left: 1rem;
  }
  .product-group-title {
    margin-left: 0.2rem;
  }
}
