.product-management-page {
  display: flex;
  flex-wrap: wrap;
  .product-order-header {
    margin-bottom: 2rem;
  }
  .search-and-filter-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    padding: 1rem;

    .search-and-filter-wrapper {
      width: 265px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .product-order-search-form {
        width: 100%;
        margin-bottom: 0.5rem;
        .search-form-group {
          width: 100%;
        }
      }

      .product-management-filter {
        display: flex;
        align-items: center;

        form {
          width: 100%;
          display: flex;
          justify-content: center;
          align-items: center;

          select {
            max-width: 10rem;
            margin-left: 1rem;
          }
        }
      }
    }
  }
  #product-list {
    .email-checkbox {
      input {
        cursor: pointer;
        margin-right: 0.5rem;
      }
    }
  }

  @media (max-width: 768px) {
    #event-ticket-category-mobile {
      margin-top: 0 !important;
    }
    .search-and-filter-container {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 0 !important;

      .product-order-search-form {
        margin-bottom: 0.2rem;
        width: 100%;
        .product-search-form {
          width: 100%;
        }
      }

      .product-management-filter {
        width: 100%;
        display: flex;

        form {
          width: 100%;

          label {
            display: none;
          }

          select {
            width: 100%;
          }
        }
      }
    }
  }
}
