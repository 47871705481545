.product-order-view-container {
  display: flex;
  flex-wrap: wrap;

  .product-order-header {
    margin-bottom: 2rem;
  }

  .search-and-filter-container {
    display: flex;
    width: 100%;
    flex-direction: column;
    align-items: flex-end;
    padding: 1rem;

    .search-and-filter-wrapper {
      width: 265px;
      display: flex;
      align-items: flex-end;
      flex-direction: column;

      .product-order-search-form {
        width: 100%;
        margin-bottom: 0.5rem;
        .search-form-group {
          width: 100%;
        }
      }

      .product-management-filter {
        display: flex;
        align-items: center;
        div {
          display: flex;
          flex-direction: column;
          justify-content: flex-end;
          align-items: flex-end;
          form {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            select {
              width: 10rem;
              margin-left: 1rem;
            }
          }
        }
      }
    }
  }

  #auto-fill-container {
    border: 1px solid #e6e9ec;
    padding: 0.5rem;
    border-radius: 5px;
    width: fit-content;
    div {
      width: 100%;
      input,
      label {
        cursor: pointer;
      }
    }
  }
  .mobile-view {
    color: black;
  }
  .product-order-report-sort {
    cursor: pointer;
  }

  .desktop-view {
    #product-order-table {
      tr {
        th {
          padding: 1rem 5px;
        }
      }

      .product-order-amount-input {
        width: 5rem !important;
      }
    }
    .product-order-save-model {
      .modal-body {
        .supplier-info {
          margin-bottom: 1rem;
        }
      }
    }
  }

  @media (max-width: 768px) {
    #event-ticket-category-mobile {
      margin-top: 0 !important;
    }
    .search-and-filter-container {
      display: flex;
      justify-content: flex-start;
      width: 100%;
      margin-bottom: 0 !important;

      .product-order-search-form {
        margin-bottom: 0.5rem;
        width: 100%;
        .product-search-form {
          width: 100%;
        }
      }

      .product-management-filter {
        width: 100%;
        display: flex;

        form {
          width: 100%;

          label {
            display: none;
          }

          select {
            width: 100%;
          }
        }
      }
    }
  }
}

.reference-number-input {
  margin-left: 1rem;
}

.isTablet {
  #content-body.product_order {
    width: 100%;
    max-width: 100% !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    .container {
      width: 100% !important;
      max-width: 100% !important;
      padding-right: 15px !important;
      padding-left: 15px !important;
      margin-right: auto !important;
      margin-left: auto !important;

      .product-order-view-table {
        width: 70% !important;
        max-width: 70% !important;
      }
    }
  }
}
