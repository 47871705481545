
#scene-wrapper {
  height: 550px;
  border: 1px solid #ced4da;
  border-radius: .25rem;

  #seats {
    height: 450px;

    .generated-seat {
      cursor: pointer;
    }
  }

  #scene {
    height: 50px;
    width: 90%;
    border-top: solid 1px #999999;
    text-align: center;
    margin: 10px auto;
    padding-top: 20px;
  }
}

#scene-wrapper-order {
  height: 600px;
  border: 1px solid #ced4da;
  border-radius: .25rem;

  #seats {
    height: 450px;
    overflow: auto;

    .generated-seat {
      cursor: pointer;
    }
  }

  #scene {
    height: 50px;
    width: 90%;
    border-top: solid 1px #999999;
    text-align: center;
    margin: 10px auto;
    padding-top: 10px;
  }

  #area-zone-name {
    height: 50px;
  }
}

#seat-information svg {
  height: 30px;
  width: 120px;

  circle {
    fill: #ebebeb;
    cursor: pointer;
  }
}

#modal-area-zone-seat svg {
  height: 30px;
  width: 30px;

  circle.rating-1 {
    fill: #28a745;
  }

  circle.rating-2 {
    fill: #007bff;
  }

  circle.rating-3 {
    fill: #dc3545;
  }
}

.seat-rating-description {
  position: relative;
  font-weight: normal;
}

.event-ticket-category-area-zone {
  background-color: $gray-100;

  h2 {
    font-size: 1rem;
    word-break: break-word;
    hyphens: auto;
    font-weight: bold;
  }
}

