.booking-table-delete-button {
  cursor: pointer;
}

.booking-table-select-box {
  min-width: 250px;
}

.booking-reservation-qty-container {

  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
    display: flex;
  }

  input {
    width: 50px;
    display: inline-block;
    text-align: center;

    @include media-breakpoint-down(sm) {
      flex-grow: 1;
    }
  }

  span {
    width: 95px;
    display: inline-block;

    @include media-breakpoint-down(sm) {
      flex-grow: 1;
    }
  }

  .booking-reservation-qty-button {
    user-select: none;
    width: auto;

    @include media-breakpoint-down(sm) {
      flex: 1;
    }

    i {
      color: $primary;
      font-size: 30px;

      &:hover {
        color: darken($gray-800, 5%);
      }

      &:active {
        color: darken($gray-800, 10%);
      }
    }

    &:hover {
      background-color: darken($gray-200, 5%);
    }

    &:active {
      background-color: darken($gray-200, 10%);
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.reservation-time {
  display: flex;
  align-items: center;
  justify-content: center;
  justify-items: center;
  flex-wrap: wrap;

}

.reservation-time > span {
  text-align: center;
  width: 150px;
  max-width: 150px;
  border: 1px solid #ced4da;
  margin: 15px;
  padding: 15px;
  box-sizing: border-box;
  flex-grow: 1;
  cursor: pointer;
  @include media-breakpoint-down(sm) {
    width: auto;
    max-width: unset;
  }
}

.reservation-time > span:hover {
  background-color: darken($gray-200, 5%);
}

.booking-area-wrapper {
  overflow-scrolling: auto;
}

#opening-hour-wrapper {
  width: 100%;
  margin-left: 150px;
  display: inline-flex !important;
  overflow: hidden;
}

#opening-hour-wrapper span.hour {
  width: 60px;
}

.booking-table-wrapper .booking-table-name {
  width: 150px;
}

.booking-table-wrapper {
  width: 100%;
  display: inline-flex !important;
  overflow: hidden;
}

.booking-table-wrapper span.booking-reservation {
  height: 25px;
  background-color: $success;
}

.booking-table-wrapper span.no-booking-reservation {
  height: 25px;
  background-color: $gray-200;
}

#booking-reservation-calendar .fc-day:hover {
  background-color: darken($gray-200, 5%);
  cursor: pointer;
}

div#booking-reservation-wrapper span {
  vertical-align: center;
}

input.booking-reservation-to-order {
  min-width: 100px;
}