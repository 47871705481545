#product-category-list {
  .material-icons {
    cursor: pointer;
  }
}

.product-category-link-select {
  width: 300px;
}

.product-category-link-delete-button {
  cursor: pointer;
}

#navbar-product-category-self-service {
  width: 100vw;
  position: sticky;
  top: 0;
  display: flex;
  padding: 0.5rem 1rem;
  justify-content: space-between;
  align-items: center;
  z-index: 100;
}

.product-category-title {
  @include media-breakpoint-down(sm) {
    margin-left: 15px;
  }
}

#product-category-list #deactivated-product-category li.list-group-item {
  background-color: #edf2f6;
}

.product-category-product-tools {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: safe center;

  .product-color-picker {
    cursor: pointer;
  }
}

#admin-content-frame {
  ul#product-category-link-list-sortable {
    li.product-category-link {
      word-break: break-all;
    }
  }
}

.product-extra-tab {
  display: flex;
  justify-content: flex-end;
}

@media (max-width: 763px) {
  body {
    overflow-x: hidden;
  }

  #banner-image-container {
    width: 100vw;
    overflow: hidden;

    img {
      width: 98%;
      height: auto; /* Maintain aspect ratio */
      object-fit: contain;
    }
  }
  #navbar-product-category-self-service {
    padding-top: 5px;
    margin-bottom: 0.5rem;

    .category-group {
      display: flex;
      flex-direction: column;
    }

    .product-search-form {
      display: none;
    }
  }

  #product-list-with-category {
    .card-box,
    .row {
      margin: 0.5rem 0;
    }
    .menu-product-row {
      padding: 1rem;
    }
  }

  #location-cart-summary {
    height: auto !important;
    .location-cart-summary-container {
      display: flex !important;
      align-items: center !important;
      font-size: medium !important;
      div {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
}
