#event-statistic-form {
  #event-statistic-form-container {
    display: flex;
    justify-content: space-around;
    #period-title {
      font-weight: 700;
    }
    .duration-picker {
      display: flex;
      align-items: center;
    }

    .period-picker-container {
      display: flex;
      .period-picker {
        display: flex;
        justify-content: center;
        .custom-date-picker {
          width: 100%;
        }
      }

      div {
        display: flex;
        margin-right: 1rem;
        input {
          margin-right: 0.2rem;
        }
      }
    }
    .filter_btn {
      display: flex;
      flex-direction: column;
      padding-top: 1rem;
      btn {
        margin-bottom: 1rem;
      }
    }
    .fliter-group {
      display: flex;

      justify-content: center;
    }
    .custom-date-picker {
      display: flex;
      justify-content: center;
      flex-wrap: wrap;
      .custom-period-container {
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
  }
}

#key-figures-container {
  display: flex;
  justify-content: space-around;
  align-items: center;
  font-weight: 700;
  font-size: 1.5rem;
}

#day-report-form {
  #date-input {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 1rem;
    label {
      margin-right: 0.5rem;
    }
  }

  .download-action {
    width: 70%;
    display: flex;
    justify-content: flex-end;
    button {
      width: fit-content;
      margin-left: 1rem;
    }
  }
}
.product-group-total {
  td {
    color: #9a9a9a;
    font-weight: 700;
  }
}

@media (max-width: 765px) {
  #key-figures-container {
    flex-wrap: wrap;
    padding: 0 !important;
    div {
      min-width: 25%;
      padding: 0.5rem;
    }
  }
  #event-statistic-form-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    .title-period-custom-container {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 100%;
    }

    .period-picker {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
  }
  .filter_btn {
    width: 50%;
  }
  .fliter-group {
    display: flex;
    flex-direction: column;
  }
}

.disabledOption {
  color: gray;
  background-color: rgba(0, 0, 0, 0.1);
}

@media (max-width: 540px) {
  .period-picker-container {
    display: flex;
    flex-direction: column;
  }
}
