.product-order-header {
  margin-bottom: 2rem;
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  align-items: center;
}

.search-and-filter-container {
  display: flex;
  width: 100%;
  flex-direction: column;
  align-items: flex-end;
  padding: 1rem;

  .search-and-filter-wrapper {
    width: 265px;
    display: flex;
    align-items: flex-end;
    flex-direction: column;

    .product-order-search-form {
      width: 100%;
      margin-bottom: 0.5rem;
      .search-form-group {
        width: 100%;
      }
    }

    .product-management-filter {
      display: flex;
      align-items: center;

      form {
        width: 100%;
        display: flex;
        align-items: center;

        select {
          max-width: 10rem;
          margin-left: 1rem;
        }
      }
    }
  }
}
#product-extra-tabs {
  a {
    text-decoration: none;
    color: #50565d;
  }

  .active {
    font-weight: bolder;
  }
}

.desktop-view {
  .inventory-count-form {
    .amount-after,
    .order-amount {
      margin-right: 10px;
      max-width: 80px;
    }
  }

  .table-responsive {
    display: flex;
  }
}

.product-pagination {
  display: flex;
  justify-content: center;
}

.stock-taking-button-container {
  display: flex;
  justify-content: flex-end;
}

.temp-save-row-highlight {
  background-color: #d0e9c6;
}

.mobile-view {
  overflow: hidden;
  ul {
    .product-detail-list-item {
      background: transparent;
    }
  }

  .list-group-item {
    padding: 5px;
    .display-lg {
      display: flex;
    }
    .product-name {
      margin-right: 5px;
      white-space: nowrap;
      max-width: 70%;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  .collapse-btn {
    margin-top: 5px;
  }
}

.report-list {
  min-width: 300px;

  .view-all-reports-btn {
    margin-top: 20px;
  }
}

.reports {
  max-height: 23rem;
  overflow: auto;
}

.modal-body {
  .conflict-user-container,
  .conflict-amount-after-container {
    display: flex;
  }
  .conflict-user,
  .conflict-amount-after {
    margin-left: 5px;
  }
}

.report-history-list {
  margin-bottom: 30px;
  .report-pagination {
    margin-top: 20px;
  }
}

.progress-report-list {
  margin-bottom: 25px;
  .report-btn {
    padding: 0;
  }
}

@media (max-width: 768px) {
  .container {
    padding: 5px;
  }
  .col-md-9 {
    padding: 0;
    .card-box {
      padding: 5px;
    }
  }

  .header {
    width: 100%;
    display: flex;
    justify-content: center;

    .header-title {
      display: none;
    }
  }
}

.isTablet {
  #content-body.inventory {
    width: 100%;
    max-width: 100% !important;
    padding-right: 15px;
    padding-left: 15px;
    margin-right: auto;
    margin-left: auto;

    .container {
      width: 100% !important;
      max-width: 100% !important;
      padding-right: 15px !important;
      padding-left: 15px !important;
      margin-right: auto !important;
      margin-left: auto !important;

      .inventory-table {
        width: 70% !important;
        max-width: 70% !important;
      }
    }
  }
}
