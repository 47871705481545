.shop-employee-mode {
  .close-suggested-product {
    position: absolute;
    top: 0px;
    right: 4px;
    font-size: 25px;
    width: 30px;
    height: 30px;
    text-align: center;
    cursor: pointer;
    border-radius: 50%;
    color: red;
    padding: 0 !important;
  }

  .card-box {
    padding: 10px 0 0 15px;
  }

  .product-row {
    padding-left: 5px;
    padding-right: 5px;

    .col-4,
    .col-sm-3,
    .col-xl-2 {
      padding-left: 5px;
      padding-right: 5px;
    }

    .shop-product-container {
      padding-bottom: 10px;
      .shop-product {
        height: 100%;
        overflow: hidden;
        max-height: 130px;
        min-height: 100px;
        word-wrap: break-word;
        hyphens: auto;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: safe center;

        &.alert-primary {
          color: #000000;
        }

        p {
          margin-bottom: 0;
        }

        &.focus,
        &:active {
          -webkit-filter: brightness(-10%);
          filter: brightness(90%);
        }
      }
    }
  }
}

.product-tab-group {
  margin-bottom: 0 !important;
  box-shadow: none;
  display: flex;
  flex-direction: column;
  a {
    text-decoration: none;
    color: #50565d;
  }

  .active {
    font-weight: bolder;
  }
  .product-extra-button-group {
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    padding-right: 0.5rem;
    .product-extra-button {
      width: 100%;
      display: flex;
      justify-content: flex-end;

      a {
        color: white;
      }
    }
  }
}
