.pin-container {
  .card-box {
    min-width: 50%;
  }
  #pin_login_form {
    width: 100%;
    .container {
      .pin {
        min-width: 200px;
      }
    }
  }

  .logout-btn {
    width: max-content;
  }
}
