.amount-container,
.date-container {
  width: 40%;
  margin-bottom: 1rem;
}

@media (max-width: 768px) {
  .current-date {
    flex-direction: column;
    align-items: flex-start;
  }
}
