#product-group-list {
  .material-icons {
    cursor: pointer;
  }
}

.product-group-link-select {
  width: 300px;
}

.product-group-link-delete-button {
  cursor: pointer;
}