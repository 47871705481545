.check-group {
  margin: 0 !important;
}
.checkout-sub-menu {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin: 0.5rem 0;
  .checkout-sub-menu-comment {
    #comment-icon {
      .material-icons {
        color: #565d65 !important;
        font-size: 2.5rem !important;
      }
    }
  }
}

@media (max-width: 576px) {
  .order-button.payment-method,
  .terminal-btn {
    font-size: 14px;
  }
}

@media (max-width: 768px) {
  .order-button.payment-method,
  .terminal-btn {
    font-size: 14px;
  }
}

@media (min-width: 992px) {
  .order-button.payment-method,
  .terminal-btn {
    font-size: 16px;
  }
}

@media (min-width: 1290px) {
  .order-button.payment-method,
  .terminal-btn {
    font-size: 20px;
  }
}
