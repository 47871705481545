#product-extra-template-list {
  .material-icons {
    cursor: pointer;
  }
}

.product-extra-template-item-select {
  width: 300px;
}

.product-extra-delete-button,
.product-extra-template-item-delete-button {
  cursor: pointer;
}

.product-extra-select-box {
  min-width: 250px;
}

.product-extra-button {
  display: flex;
  justify-content: flex-end;
}
