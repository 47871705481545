#user-setting-table {
  min-height: 500px;
  #module-selector {
    width: 250px;

    .dropdown-menu {
      width: 200px;
      max-height: 300px;
      padding-bottom: 1rem;
      .hidden-option {
        display: none;
      }
    }
  }
}

.classic-product-column {
  display: flex;
  justify-content: space-between;
  cursor: pointer;
}
