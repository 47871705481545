div.key-figures div#key-figures-number-of-sold-tickets {
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
}

div#event-information div.form-check-inline {
  @include media-breakpoint-down(md) {
    margin-right: 0;
  }
}

div#event-information div.form-check {
  padding-left: 0.85rem;
}

div#location-statistic-sum {
  border-bottom-width: 3px !important;
}
