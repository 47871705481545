
#event-form-question-form {

  .event-form-question-reorder {
    cursor: pointer;
  }

  .delete-event-form-question-button {
    cursor: pointer;
  }

  .add-event-form-answer-option-button {
    cursor: pointer;
  }

  .event-form-answer-option-reorder-link {
    display: inline-block;
    cursor: pointer;
  }

  .event-form-answer-option-delete-button {
    display: inline-block;
    cursor: pointer;
  }

  .event-form-answer-option-wrapper {
    max-height: 400px;
    overflow-x: hidden;
    overflow-y: auto;

  }
}

.event-form-question-and-answer-wrapper {
  padding: 10px;
  margin: 10px 0;
  border: 1px solid #ced4da;
  border-radius: 5px;
}

.event-form-question-wrapper {
  padding-top: 7px;

  hr {
    border-top: 1px solid #ced4da;
    margin-bottom: 7px;
  }
}

.event-form-question-order-form {
  font-size: 1.2rem;
}