.front-page-container {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;

  .front-page-title {
    padding: 0 3rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1.5rem;

    .front-btn-group {
      display: flex;

      .btn {
        margin-right: 1rem;
      }
    }
  }

  .information-box {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    height: 100%;
  }

  .box-container {
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .management-info {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: flex-start;
      margin: 0.5rem;
      text-align: center;
      cursor: pointer;
      width: 150px;
      min-height: 150px;
      transition: transform 0.3s ease;

      :hover {
        transform: scale(1.1);
      }
    }
  }
}

@media (max-width: 768px) {
  .front-page-title {
    padding: 1rem !important;
    text-align: center;
    .title {
      font-size: 2.5rem !important;
    }
    .front-image {
      display: none;
    }

    .front-btn-group {
      justify-content: center;
      margin-top: 1rem !important;

      .btn {
        margin: 0 0.5rem !important;
      }
    }
  }
  .box-container {
    display: flex;
    margin: 2rem;

    .management-info {
      width: 100%;
    }
  }
}
