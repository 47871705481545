
#globalPopupMessage {
  position: fixed;
  width: 200px;
  height: 160px;
  top: 50%;
  left: 50%;
  margin-top: -100px;
  margin-left: -100px;
  opacity: 0.8;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14), 0 1px 5px 0 rgba(0, 0, 0, 0.12), 0 3px 1px -2px rgba(0, 0, 0, 0.2);

  .material-icons {
    font-size: 6rem;
  }
}

input[type=checkbox], input[type=radio] {
  width: 20px;
  height: 20px;
  margin-top: 0.2rem;
}

.form-check {
  padding-left: 1.45rem;
}

.form-check-input {
  margin-left: -1.45rem;
}

.event-ticket-category-reorder, .event-ticket-category-mobile-reorder, .slider-reorder {
  cursor: move;
}

.sortable-ghost {
  background: #dde8f9;
  border: 1px dotted #ccc;
  margin: 0.25rem 0;
}

div#organizer-sidebar-wrapper nav.sidebar div.sidebar-sticky i.material-icons {
  color: #fff;
  font-size: 24px;
  cursor: pointer;
}

html {
  .picker, .picker__holder {
    max-width: 96%;
  }
  div#admin-content-frame {
    .picker, .picker__holder {
      max-width: 90%;
    }

    #opening-hours-exception-registration-form {
      .picker, .picker__holder {
        max-width: 260px;
      }
    }
  }
}

.btn-icon-text {
  display: flex;
  justify-content: center;
  align-items: center;

  .material-icons {
    padding-bottom: 0;
  }
}

/*.input-icon:before {
  font-family: "Material Icons";
  &-calendar {
    content: "\e5cc";
  }
}*/

