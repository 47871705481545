.input-field {
  display: none;

  .drag-edit-btn {
    display: flex;
  }
  input {
    width: 300px;
  }
  #supplier-select,
  #mva-select,
  #product-group-unique-select {
    width: 300px;
  }
}

#live-edit-btn {
  margin-bottom: 1rem;
  margin-top: 0.5rem;
}

#event_ticket_category_table {
  thead {
    tr {
      th {
        padding: 5px;
      }
    }
  }
  .material-icons {
    font-size: 24px;
  }

  .material-icons-asc {
    transform: rotate(180deg);
  }

  td {
    width: min-content;
    vertical-align: middle;
    padding-left: 1%;
    user-select: none;

    label {
      margin: 0;
      display: flex;
      width: min-content;
      height: 100%;
      position: relative;
      justify-content: center;
      align-items: center;

      .spinner {
        position: absolute;
        width: 100%;
        height: 100%;
        background-color: rgba(255, 255, 255, 0.2);
        display: none;
        justify-content: center;
        align-items: center;

        .loader {
          width: 15px;
          height: 15px;
        }
      }
    }
  }

  .product-name,
  .product-sku {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    max-width: 300px;

    strong {
      pointer-events: none;
    }
  }

  .mva-selector {
    width: 4.5rem;
  }

  .supplier-selector-td {
    width: 7rem;
  }

  input,
  select {
    padding: 5px !important;
  }

  .coverage-of-contribution-input {
    padding-left: 3px !important;
    width: 5rem;
  }

  .sort {
    cursor: pointer;
  }

  .highlight {
    background-color: #d0e9c6;
  }

  .delete-btn {
    margin: 0 !important;
    padding: 0 !important;
    display: flex;
  }
}

.product-extra-label {
  margin: auto 0;
}

.product-qty-container {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: nowrap;
  gap: 0.5rem;
}

.product-qty-container {
  @include media-breakpoint-down(sm) {
    margin-top: 1rem;
    display: flex;
  }

  input {
    width: 50px;
    display: inline-block;
    text-align: center;

    @include media-breakpoint-down(sm) {
      flex-grow: 1;
    }
  }

  span {
    width: 95px;
    display: inline-block;

    @include media-breakpoint-down(sm) {
      flex-grow: 1;
    }
  }

  .product-qty-button {
    user-select: none;
    width: auto;

    @include media-breakpoint-down(sm) {
      flex: 1;
    }

    i {
      color: $primary;
      font-size: 30px;

      &:hover {
        color: darken($gray-800, 5%);
      }

      &:active {
        color: darken($gray-800, 10%);
      }
    }

    &:hover {
      background-color: darken($gray-200, 5%);
    }

    &:active {
      background-color: darken($gray-200, 10%);
    }

    &:focus {
      box-shadow: none;
    }
  }
}

.product-description,
.allergens {
  color: #666666;
}

.product-title,
.product-description,
.allergens,
.location-description {
  word-break: break-word;
  hyphens: auto;
}

.product-image {
  border-radius: 0.25rem;
}

.product-image-wrapper {
  height: 190px;

  @include media-breakpoint-down(sm) {
    height: 90px;
  }
}

.product-image-wrapper img {
  object-fit: contain;
  height: 100%;
  width: 100%;
  cursor: pointer;
}

#product-extra-tabs {
  box-shadow: unset !important;
  border-radius: unset;
}

#event_ticket_category_form {
  .card-box {
    border-top: none;
    box-shadow: 0 0 0 0 rgba(0, 0, 0, 0) !important;
    -moz-border-radius-topleft: unset;
    -moz-border-radius-topright: unset;
  }
  .category-multi-selector {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    .bootstrap-select {
      width: 25rem;
    }
  }

  .form-check {
    height: 38px;
  }

  .menu-product-table {
    th,
    td {
      min-width: 100px;
      width: 200px;

      &.name-column {
        min-width: 200px;
        max-width: 500px;
      }
    }
  }
}

.search-form-container {
  width: 100%;
  display: flex;
  flex-flow: no-wrap;
  justify-content: center;
  align-items: center;
  margin-bottom: 1.5rem;
}

.choose-product-list-container {
  display: flex;
  flex-direction: column;
  max-height: 211px;
  overflow: auto;

  .list-item {
    padding: 0.5rem;
    cursor: pointer;
    font-size: 0.8rem;
    border-radius: 5px;

    &:hover {
      background-color: #f5f5f5;
    }
  }
}

ul#product-category-list-sortable i.material-icons {
  color: #212529;
}

.product-lightbox-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 1040;
  width: 100vw;
  height: 100vh;
  background-color: #000;
  opacity: 0.5;
}

.product-lightbox-wrapper {
  z-index: 1050;
  position: fixed;
  left: 50%;
  top: 50%;
  margin: 0 auto;
  transform: translate(-50%, -50%);

  .line-1 {
    position: absolute;
    display: block;
    height: 30px;
    width: 3px;
    background: #ffffff;
    top: -30px;
    right: 10px;
    -webkit-transform: rotate(45deg);
  }

  .line-2 {
    position: absolute;
    display: block;
    height: 30px;
    width: 3px;
    background: #ffffff;
    top: -30px;
    right: 10px;
    -webkit-transform: rotate(-45deg);
  }
}

.blur-background #content {
  filter: blur(5px);
}

#filter {
  width: 100%;
  margin-bottom: 10px;
}

.hidden {
  display: none;
}
.select2-container--bootstrap4 .select2-results__option[aria-disabled="true"] {
  color: #999;
  cursor: not-allowed;
}

#filter-form-container {
  display: flex;
  width: 50%;
  justify-content: space-around;
  align-items: flex-end;
  margin: 0 auto;
  div {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
  .select-width {
    width: 10rem;
  }
}

@media (max-width: 768px) {
  #extra-product-select-container {
    margin-bottom: 0.5rem;
  }
  #filter-form-container {
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
}
