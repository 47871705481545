.list-group-item {
  user-select: none;
}

#context-menu {
  display: none;
  position: absolute;
  z-index: 1000;
  width: 300px;
  border: 1px solid #ccc;
  ul {
    list-style: none;
    padding: 0;
    margin: 0;
    li {
      padding: 8px 12px;
      cursor: pointer;
    }
    li:hover {
      background-color: #ccc;
    }
  }
}
