@import url("https://fonts.googleapis.com/css2?family=Ubuntu:wght@300&display=swap");
@import "../../vendor/twbs/bootstrap/scss/_functions.scss";
@import "../../vendor/twbs/bootstrap/scss/_variables.scss";
@import "../../vendor/twbs/bootstrap/scss/mixins/_breakpoints.scss";
@import "../site/css/site";
@import "../utils/css/utils";
@import "../utils/css/cookie-consent";
@import "../site/css/admin";
@import "../product/css/eventticketcategory";
@import "../location/css/event";
@import "../location/css/statistic";
@import "../location/css/dashboard";
@import "../admin/css/statistic";
@import "../order/css/order";
@import "../eventform/css/eventform";
@import "../area/css/areazone";
@import "../payoutreport/css/payoutreport";
@import "../productcategory/css/productcategory";
@import "../productcategoryselfservice/css/productcategory";
@import "../productgroup/css/productgroup";
@import "../productextra/css/productextra";
@import "../booking/css/booking";
@import "../promocode/css/promocode";
@import "../product/css/product";
@import "../inventory/css/inventory";
@import "../supplier/css/supplier";
@import "../productorder/css/productorder";
@import "../productmanagement/css/productmanagement";
@import "../frontPage/css/frontPage";
@import "../statistic/css/statistic";
@import "../user/css/user-view";
@import "../customer/css/customer";
@import "../customergroup/css/customer_group";
@import "../organizer/css/organizerView";
@import "../location/css/posClassic";
@import "../inventorystockhistory/css/inventoryStockHistory";
@import "../producttable/css/productTable";
@import "../printer/css/printer";
@import "../zreport/css/zreport";
@import "../creditorder/css/creditOrder";
@import "../templateimport/css/template";
