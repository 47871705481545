.arrow-icon {
  width: 10px;
  height: 10px;
  border: solid black;
  border-width: 0 2px 2px 0;
  display: inline-block;
  padding: 2px;
  transform: rotate(45deg);
  transition: transform 0.3s ease;
}

div[aria-expanded="true"] .arrow-icon {
  transform: rotate(225deg);
}
