.promo-code-product-select {
  width: 100%;
}

.promo-code-product-delete-button {
  cursor: pointer;
}

.promo-code-product-wrapper {
  width: 100%;
}

.promo-code-product-mobile {
  @include media-breakpoint-up(sm) {
    display: none;
  }
}

.promo-code-product-text {
  @include media-breakpoint-down(sm) {
    font-weight: bold;
  }
}